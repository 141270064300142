import Vue from "vue";
import Router from "vue-router";
import store from "@/store";
Vue.use(Router);

const viewport = {
  content: "width=device-width, initial-scale=1.0, user-scalable=no"
}

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      redirect: '/home'
    },
    {
      path: '/layout',
      name: 'layout',
      component: () => import("@/layout/index.vue"),
      children: [
        {
          path: "/home",
          name: "home",
          redirect: '/formsPanel',
          component: () => import("@/views/Index.vue"),
          meta: {title: '审批中心', viewport: viewport}
        },
        {
          path: "/workspace",
          name: "workspace",
          component: () => import("@/views/workspace/WorkSpace.vue"),
          meta: {title: '工作区', viewport: viewport}
        },
        {
          path: "/workspace/process/instance/tabs",
          name: "processInstanceTabs",
          component: () => import("@/views/workspace/process/ProcessInstanceTabs.vue"),
          meta: {title: '流程详情', viewport: viewport}
        },
        {
          path: "/formsPanel",
          name: "formsPanel",
          component: () => import("@/views/admin/FormsPanel.vue"),
          meta: {title: '流程面板', viewport: viewport}
        },
      ]
    },
    {
      path: "/admin/design",
      name: "design",
      component: () => import("@/views/admin/FormProcessDesign.vue"),
      meta: {title: '表单流程设计', viewport: viewport}
    },
    {
      path: "/login",
      name: "login",
      component: () => import("@/views/login/index.vue"),
      meta: {title: '登录', viewport: viewport}
    },
  ]
})

let flag = false
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  if (to.meta.content) {
    let head = document.getElementByTagName('head')
    let meta = document.createElemnet('meta')
    meta.name = 'viewport'
    meta.content = "width=device-width, initial-scale=1.0, user-scalable=no"
    head[0].appendChild(meta)
  }

  if (to.path.toLocaleLowerCase() === '/login') {
    if (localStorage.getItem('accessToken') && !to.query.ticket ) return next(from.fullPath)
    return next()
  }

  if (!localStorage.getItem('accessToken')) {
    return  next({path: '/login', replace: true})
  }

  if (!flag) {
    store.dispatch('getUserInfo').then(() => {
      flag = true
      next({ ...to, replace: true })
    })
  }

  if (to.path === '/home') {
    next({ path: '/formsPanel', replace: true })
  }
  next();
})

export default router;
