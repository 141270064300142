import Vue from 'vue'
import Vuex from 'vuex'
import {getUserInfo} from "@/api/login";

Vue.use(Vuex)

function omitKey(obj, keyToOmit) {
  const { [keyToOmit]: _, ...rest } = obj
  return rest
}

const noPersistence = ['nodeMap', 'isEdit', 'selectedNode', 'selectFormItem', 'design', 'runningList', 'noTakeList', 'endList', 'diagramMode']
function persistencePlugin(config) {
  const { keyName = 'vuex', storage = 'localStorage' } = config

  return function (store) {
    const data = JSON.parse(window[storage].getItem(keyName) || '{}')
    if (JSON.stringify(data) !== '{}') store.replaceState(Object.assign(store.state, data))
    store.subscribe((mutation, state) => {
      let newData = { ...state }
      noPersistence.forEach(key => {
        newData = omitKey(newData, key)
      })
      window[storage].setItem(keyName, JSON.stringify(newData))
    })
  }
}



export default new Vuex.Store({
  state: {
    nodeMap: new Map(),
    isEdit: null,
    selectedNode: {},
    selectFormItem: null,
    design:{},
    runningList: [],
    noTakeList: [],
    endList: [],
    diagramMode: 'design',
    user: {
      username: '',
      userId: '',
      avatar: '',
      name: '',
      id: '',
      selected: true,
      sex: 0,
      type: "user"
    }
  },
  mutations: {
    selectedNode(state, val) {
      state.selectedNode = val
    },
    loadForm(state, val){
      state.design = val
    },
    setIsEdit(state, val){
      state.isEdit = val
    },
    SET_USER(state, data) {
      state.user.username = data.username
      state.user.name = data.username
      state.user.userId = data.userId
      state.user.id = data.userId
      state.user.avatar = data.avatar
    }
  },
  getters: {
    userInfo(state) {
      return state.user
    }
  },
  actions: {
    setUser({ commit }, data) {
      commit('SET_USER', data)
    },
    getUserInfo({ commit }, data) {
      return new Promise((resolve, reject) => {
        getUserInfo().then(res => {
          if (res.data.code === 200) {
            commit('SET_USER', { username: res.data.data.name, ...res.data.data })
            return resolve()
          }

          reject()
        }).catch(() => {
          reject()
        })
      })
    }
  },
  plugins: [persistencePlugin({ keyName: 'vuex', storage: 'sessionStorage' })],
})
