
import request from '@/api/request.js'

// 登录
export function login() {
  return request({
    url: 'oauth2/token/get',
    method: 'get',
    params: {
      appId: 'silverdawn-workflow-server',
      secretKey: 'bh7zWGOwszppsaMJa4ly7DFo2Bz4OcHo'
    }
  })
}

/**
 * 获取用户信息
 */
export function getUserInfo() {
  return request.get('/sso/userInfo')
}

/**
 * Ticket更换token、
 * @param params
 */
export function ticketByToken(params) {
  return request.get('/sso/doLoginByTicket', {params})
}

/**
 * 获取oss登录地址
 * @param data
 */
export function getSsoAuthUrl(data) {
  return request.post('/sso/getSsoAuthUrl', data)
}

/**
 * 退出登录
 */
export function logout() {
  return request.post('/sso/logout')
}
